import {useEffect, useState} from "react";

const tg = window.Telegram.WebApp
const tgWebView = window.Telegram.WebView

export const useTelegram = () => {

    const [backCallback, setBackCallback] = useState(null)
    const [mainCallback, setMainCallback] = useState(null)

    const onClose = () => {
        tg.close()
    }

    useEffect(() => {
        if(backCallback === null) return
        tg.onEvent('backButtonClicked', backCallback)
        return () => tg.offEvent('backButtonClicked', backCallback)
    }, [backCallback])

    const checkVersion = () => {
        return tg.isVersionAtLeast("6.1")
    }

    useEffect(() => {
        if(mainCallback === null) return
        tg.onEvent('mainButtonClicked', mainCallback)
        return () => tg.offEvent('mainButtonClicked', mainCallback)
    }, [mainCallback])

    const showBackButton = (show) => {
        show ? tg.BackButton.show() : tg.BackButton.hide()
    }

    return {
        tg,
        tgWebView,
        colorScheme: tg.colorScheme,
        user: tg.initData?.user,
        MainButton: tg.MainButton,
        onClose,
        checkVersion,
        showBackButton,
        setBackCallback,
        setMainCallback
    }
}