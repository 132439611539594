import {createSlice} from '@reduxjs/toolkit'
import {fetchChatMathRules} from "./apiSlice";

const initialState = {
    rules: null
}

export const matchRule = createSlice({
    name: 'matchRule',
    initialState: initialState,
    reducers: {
        addMatchRule: (state, action) => {
            state.rules.push(action.payload)
        },
        updateMatchRule: (state, action) => {
            let rule = action.payload
            let index = state.rules.findIndex(r => r.id === rule.id)
            if (index !== -1) {
                state.rules[index] = rule
            }
        },
        removeMatchRule: (state, action) => {
            let index = state.rules.findIndex(r => r.id === action.payload)
            state.rules.splice(index, 1)
        },
        setMatchRules: (state, action) => {
            state.rules = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchChatMathRules.fulfilled, (state, action) => {
            state.rules = action.payload
        })
    },
})

export const selectMatchRules = state => state.matchRule.rules;

export const { setMatchRules, removeMatchRule, addMatchRule, updateMatchRule } = matchRule.actions

export default matchRule.reducer