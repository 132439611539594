const permissions = {
    MATCH_RULES: "MATCH_RULES",
    MAILING: "MAILING",
    SELECT_GROUPS: "SELECT_GROUPS",
    ROLES: "ROLES",
    STAFF_CARD: "STAFF_CARD",
    USERS: "USERS",
    USERS_MANAGEMENT: "USERS_MANAGEMENT",
    SCHEDULE_UPLOAD: "SCHEDULE_UPLOAD"
}

export const baseCheck = (permission) => {
    return (user) => {
        if(permission === null)
            return true;
        if(user === null)
            return false
        return user.admin === true || (user.permissions.length > 0 && (user.permissions.includes(permission) || user.permissions.includes('*')))
    }
}

export default permissions