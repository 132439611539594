import {createSlice} from '@reduxjs/toolkit'
import {fetchGetStaffCardById} from "./apiSlice";

const initialState = {
    data: null
}

export const staffCardSlice = createSlice({
    name: 'staffCard',
    initialState: initialState,
    reducers: {
        setContactData: (state, action) => {
            state.data = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetStaffCardById.fulfilled, (state, action) => {
                state.data = action.payload.card
            })
    },
})

export const selectStaffCard = state => state.staffCard.data;

export const { setContactData } = staffCardSlice.actions

export default staffCardSlice.reducer