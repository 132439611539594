import {configureStore} from '@reduxjs/toolkit'
import authSlice from "./authSlice";
import userSlice from "./userSlice";
import errorSlice from "./errorSlice";
import groupSlice from "./groupSlice";
import apiSlice from "./apiSlice";
import saveStatusSlice from "./saveStatusSlice";
import contactDataSlice from "./staffCardSlice";
import staffSlice from "./staffSlice";
import scrollSlice from "./scrollSlice";
import selectedGroupSlice from "./selectedGroupSlice";
import matchRuleSlice from "./matchRuleSlice";

export default configureStore({
    reducer: {
        auth: authSlice,
        user: userSlice,
        error: errorSlice,
        group: groupSlice,
        selectedGroup: selectedGroupSlice,
        saveStatus: saveStatusSlice,
        staffCard: contactDataSlice,
        staff: staffSlice,
        scroll: scrollSlice,
        matchRule: matchRuleSlice,
        api: apiSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
})