import { createSlice } from '@reduxjs/toolkit'

export const scrollSlice = createSlice({
    name: 'scroll',
    initialState: {},
    reducers: {
        saveScrollPosition: (state, action) => {
            const {key, position} = action.payload
            state[key] = position
        },
        clearScrollPosition: (state, action) => {
            delete state[action.payload]
        }
    }
})

export const { saveScrollPosition, clearScrollPosition } = scrollSlice.actions

export const selectScrollPosition = key => {
    return state => {
        return state.scroll[key]
    }
}

export default scrollSlice.reducer