import React, {useEffect} from 'react';
import {routes} from "./routes";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import './App.css'
import {APIService} from "./api/APIService";
import Loader from "./components/Loader/Loader";
import errorIcon from './img/error.png'
import {useTelegram} from "./hooks/useTelegram";
import {useDispatch, useSelector} from "react-redux";
import {loadToken, selectAuthToken, setToken} from "./store/authSlice";
import {selectCurrentUser} from "./store/userSlice";
import {selectError, setError} from "./store/errorSlice";
import {fetchMeQuery} from "./store/apiSlice";

const router = createBrowserRouter(routes)

const App = () => {
    const dispatch = useDispatch()
    const token = useSelector(selectAuthToken)
    const currentUser = useSelector(selectCurrentUser)
    const error = useSelector(selectError)

    const {tg, showBackButton, checkVersion, colorScheme} = useTelegram();

    useEffect(() => {
        if(!error) return
        showBackButton(false)
    }, [error, showBackButton])

    async function sendVerify() {
        let response = await APIService.verify({
            initData: tg.initData
        })
        if (response.status / 100 === 2) {
            let j = await response.json()
            dispatch(setToken({
                token: j.token,
                expiration: Date.now() + j.expiration
            }))
        } else {
            dispatch(setError("Ошибка верификации"))
        }
    }

    useEffect(() => {
        document.querySelector("body").setAttribute("theme", colorScheme)
    }, [colorScheme])

    useEffect(() => {
        if(tg && tg.initData) {
            tg.onEvent("themeChanged", () => {
                document.querySelector("body").setAttribute("theme", tg.colorScheme)
            })
            tg.ready()
            if(!checkVersion()) {
                dispatch(setError("Ваша версия Telegram API не поддерживается"))
                return;
            }
            sendVerify()
        } else {
            const searchParams = new URLSearchParams(window.location.search)
            if(searchParams.has("token")) {
                const token = searchParams.get("token");
                dispatch(setToken({
                    token,
                    expiration: -1
                }))
                searchParams.delete("token")
                window.location.search = searchParams.toString()
            } else if(localStorage.getItem("auth") === null) {
                dispatch(setError("Ошибка инициализации\nПерезапустите приложение"))
                return
            } else {
                dispatch(loadToken())
            }
            document.querySelector("body").classList.add("dark")
        }
    }, [])

    useEffect(() => {
        if(!token.token) return
        dispatch(fetchMeQuery())
    }, [dispatch, token])

    if(error) return (
        <div style={{height: '95vh'}} className='d-flex flex-column justify-center items-center'>
            <img  style={{marginBottom: 20}} src={errorIcon} alt="error"/>
            <h1 className='text-center'>{error}</h1>
        </div>
    )
    if(currentUser) return (
        <RouterProvider router={router} />
    )
    return (
        <div className="loader">
            <Loader colorScheme={colorScheme}/>
        </div>
    );
};

export default App;