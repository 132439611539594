import {createSlice} from '@reduxjs/toolkit'
import {fetchEditNameById, fetchMeQuery} from "./apiSlice";

const initialState = {
    currentUser: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            state.currentUser = action.payload
        }
    },
    extraReducers: (builder) => builder
        .addCase(fetchMeQuery.fulfilled, (state, action) => {
            state.currentUser = action.payload
        })
        .addCase(fetchEditNameById.fulfilled, (state, action) => {
            const {firstName, lastName, middleName} = action.payload.name
            state.currentUser.firstName = firstName
            state.currentUser.lastName = lastName
            state.currentUser.middleName = middleName
        })
})

export const {setUser} = userSlice.actions

export const selectCurrentUser = state => state.user.currentUser;

export default userSlice.reducer