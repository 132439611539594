import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,
    expiration: -1
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setToken: (state, action) => {
            const {token, expiration} = action.payload;
            state.token = token
            state.expiration = expiration
            localStorage.setItem("auth", JSON.stringify({token, action}))
        },
        loadToken: (state) => {
            try {
                const authItem = localStorage.getItem("auth");
                if(authItem === null)
                    return
                const authData = JSON.parse(authItem)
                state.token = authData.token;
                state.expiration = authData.expiration;
            } catch (e) {}
        }
    }
})

export const { setToken, loadToken } = authSlice.actions

export const selectAuthToken = state => state.auth

export default authSlice.reducer