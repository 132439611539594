import React from 'react';
import style from './Loader.module.css'

const Loader = ({colorScheme}) => {
    return (
        <div className={style.container}>
            <div className={style.loader}>
                <div className={[style.box, style.box0].join(' ')}>
                    <div/>
                </div>
                <div className={[style.box, style.box1].join(' ')}>
                    <div/>
                </div>
                <div className={[style.box, style.box2].join(' ')}>
                    <div/>
                </div>
                <div className={[style.box, style.box3].join(' ')}>
                    <div/>
                </div>
                <div className={[style.box, style.box4].join(' ')}>
                    <div/>
                </div>
                <div className={[style.box, style.box5].join(' ')}>
                    <div/>
                </div>
                <div className={[style.box, style.box6].join(' ')}>
                    <div/>
                </div>
                <div className={[style.box, style.box7].join(' ')}>
                    <div/>
                </div>
                <div className={[style.box, style.box8].join(' ')}>
                    <div/>
                </div>
            </div>
        </div>
    )
};

export default Loader;