import React from 'react';
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../store/userSlice";

const baseAction = () => {
    return (
        <h1 className='fixed-center'>Доступ запрещен!</h1>
    )
}

const ProtectedRoute = ({children, check, action = baseAction}) => {
    const user = useSelector(selectCurrentUser)
    if(check !== undefined && !check(user)) {
        return action()
    }
    return children
};

export default ProtectedRoute;