import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {APIService} from "../api/APIService";

const baseQuery = async (query, thunkAPI) => {
    try {
        const response = await query(thunkAPI.getState().auth.token)
        if (Math.floor(response.status / 100) === 2) {
            return await response.json()
        } else if (Math.floor(response.status / 100) === 4) {
            let d = await response.json()
            return thunkAPI.rejectWithValue(d)
        }
        return thunkAPI.rejectWithValue(response.data)
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
}

export const fetchMeQuery = createAsyncThunk(
    'api/fetchMeQuery',
    async (userData, thunkAPI) => {
        return baseQuery(
            token => APIService.me(token),
            thunkAPI
        )
    }
)

export const fetchGroupsQuery = createAsyncThunk(
    'api/fetchGroupsQuery',
    async (userData, thunkAPI) => {
        return baseQuery(
            token => APIService.getGroups(token),
            thunkAPI
        )
    }
)

export const fetchRolesQuery = createAsyncThunk(
    'api/fetchRolesQuery',
    async (userData, thunkAPI) => {
        return baseQuery(
            token => APIService.getRoles(token),
            thunkAPI
        )
    }
)

export const fetchNotifySettingsByIdQuery = createAsyncThunk(
    'api/fetchNotifySettingsByIdQuery',
    async ({notifyId}, thunkAPI) => {
        return baseQuery(
            token => APIService.getNotifySettingsById(token, notifyId),
            thunkAPI
        )
    }
)

export const fetchUserByIdQuery = createAsyncThunk(
    'api/fetchUserByIdQuery',
    async ({userId}, thunkAPI) => {
        return baseQuery(
            token => APIService.getUser(token, userId),
            thunkAPI
        )
    }
)

export const fetchEditUserByIdQuery = createAsyncThunk(
    'api/fetchEditUserByIdQuery',
    async ({userId, data}, thunkAPI) => {
        return baseQuery(
            token => APIService.editUser(token, userId, data),
            thunkAPI
        )
    }
)

export const fetchEditNotifySettingsByIdQuery = createAsyncThunk(
    'api/fetchEditNotifySettingsByIdQuery',
    async ({notifyId, data}, thunkAPI) => {
        return baseQuery(
            token => APIService.editNotifySettingsById(token, notifyId, data),
            thunkAPI
        )
    }
)

export const fetchChatGroupsQuery = createAsyncThunk(
    'api/fetchChatGroupsQuery',
    async ({chatId}, thunkAPI) => {
        return baseQuery(
            token => APIService.getSelectedGroups(token, chatId),
            thunkAPI
        )
    }
)

export const createChatMathRule = createAsyncThunk(
    'api/createChatMathRule',
    async ({chatId, data}, thunkAPI) => {
        return baseQuery(
            token => APIService.createChatMathRule(token, chatId, data),
            thunkAPI
        )
    }
)

export const fetchChatMathRules = createAsyncThunk(
    'api/fetchChatMathRules',
    async ({chatId}, thunkAPI) => {
        return baseQuery(
            token => APIService.fetchChatMathRules(token, chatId),
            thunkAPI
        )
    }
)

export const fetchChatMathRuleById = createAsyncThunk(
    'api/fetchChatMathRuleById',
    async ({chatId, ruleId}, thunkAPI) => {
        return baseQuery(
            token => APIService.fetchChatMathRuleById(token, chatId, ruleId),
            thunkAPI
        )
    }
)

export const fetchGetMailing = createAsyncThunk(
    'api/fetchGetMailing',
    async (userData, thunkAPI) => {
        return baseQuery(
            token => APIService.mailingGet(token),
            thunkAPI
        )
    }
)

export const fetchEditMailing = createAsyncThunk(
    'api/fetchEditMailing',
    async ({data}, thunkAPI) => {
        return baseQuery(
            token => APIService.mailingEdit(token, data),
            thunkAPI
        )
    }
)

export const fetchStartMailing = createAsyncThunk(
    'api/fetchStartMailing',
    async ({data}, thunkAPI) => {
        return baseQuery(
            token => APIService.mailingStart(token, data),
            thunkAPI
        )
    }
)

export const fetchDeleteChatMathRuleById = createAsyncThunk(
    'api/fetchDeleteChatMathRuleById',
    async ({chatId, ruleId}, thunkAPI) => {
        return baseQuery(
            token => APIService.fetchDeleteChatMathRuleById(token, chatId, ruleId),
            thunkAPI
        )
    }
)

export const fetchGetStaffCardById = createAsyncThunk(
    'api/fetchGetStaffCardById',
    async (userId, thunkAPI) => {
        return baseQuery(
            token => APIService.getStaffCardById(token, userId),
            thunkAPI
        )
    }
)

export const fetchEditStaffCardById = createAsyncThunk(
    'api/fetchEditStaffCardById',
    async ({value, chatId}, thunkAPI) => {
        return baseQuery(
            token => APIService.editStaffCardById(token, chatId, value),
            thunkAPI
        )
    }
)

export const fetchEditNameById = createAsyncThunk(
    'api/fetchEditNameById',
    async ({userId, value}, thunkAPI) => {
        return baseQuery(
            token => APIService.editName(token, userId, {
                name: {
                    firstName: value.firstName.value,
                    lastName: value.lastName.value,
                    middleName: value.middleName.value
                }
            }),
            thunkAPI
        )
    }
)


export const fetchEditChatGroups = createAsyncThunk(
    'api/fetchEditChatGroups',
    async ({value, chatId}, thunkAPI) => {
        return baseQuery(
            token => APIService.editGroups(token, chatId, value),
            thunkAPI
        )
    }
)

export const fetchSearchContacts = createAsyncThunk(
    'api/fetchSearchContacts',
    async ({skip, size, search}, thunkAPI) => {
        return baseQuery(
            token => APIService.searchContacts(token, {skip, size, search}),
            thunkAPI
        )
    }
)

export const fetchEditMatchRule = createAsyncThunk(
    'api/fetchEditMatchRule',
    async ({chatId, ruleId, data}, thunkAPI) => {
        return baseQuery(
            token => APIService.editMatchRule(token, chatId, ruleId, data),
            thunkAPI
        )
    }
)

export const apiSlice = createSlice({
    name: 'api',
    initialState: null
})
apiSlice.middleware = store => next => action => {
    if(action.type.startsWith("api/")) {

    }
    return next(action)
}

export default apiSlice