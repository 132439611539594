import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sources: []
}

export const saveStatusSlice = createSlice({
    name: 'saveStatus',
    initialState: initialState,
    reducers: {
        addSaveSource: (state, action) => {
            const source = state.payload;
            if(state.sources.indexOf(source) > -1)
                return
            state.sources.push(source)
        },
        removeSaveSource: (state) => {
            const source = state.payload;
            state.sources.splice(state.sources.indexOf(source), 1)
        }
    }
})

export const { addSaveSource, removeSaveSource } = saveStatusSlice.actions

export const selectIsSave = state => state.saveStatus.sources.length > 0

export default saveStatusSlice.reducer