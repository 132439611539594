import {createSlice} from '@reduxjs/toolkit'
import {fetchGroupsQuery} from "./apiSlice";

const initialState = {
    groups: null
}

export const groupSlice = createSlice({
    name: 'group',
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchGroupsQuery.fulfilled, (state, action) => {
            state.groups = action.payload
        })
    },
})

export const selectGroups = state => state.group.groups;

export default groupSlice.reducer