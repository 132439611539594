import React, {Suspense} from 'react'
import Loader from "./components/Loader/Loader";
import ProtectedRoute from "./components/ProtectedRoute";
import {Navigate} from "react-router-dom";
import permissions, {baseCheck} from "./permissions";

const AppPage = React.lazy(() => import("./pages/App/AppPage/AppPage"))
const RegisterPage = React.lazy(() => import("./pages/Register/RegisterPage"))
const RegisterSelectPage = React.lazy(() => import("./pages/Register/RegisterSelectPage/RegisterSelectPage"))
const RegisterTeacherPage = React.lazy(() => import("./pages/Register/RegisterTeacherPage"))
const RegisterStudentPage = React.lazy(() => import("./pages/Register/RegisterStudentPage"))
const ContactsPage = React.lazy(() => import("./pages/App/Root/StaffPage/StaffDataPage"))
const MailingPage = React.lazy(() => import("./pages/App/MailingPage"))
const UsersListPage = React.lazy(() => import("./pages/App/UsersListPage/UsersListPage"))
const UserPage = React.lazy(() => import("./pages/App/UserPage"))
const ProfilePage = React.lazy(() => import("./pages/App/Root/ProfilePage/ProfilePage"))
const StaffCardPage = React.lazy(() => import("./pages/App/StaffCardPage"))
const GroupsPage = React.lazy(() => import("./pages/App/GroupsPage"))
const StaffListPage = React.lazy(() => import("./pages/App/Root/StaffPage/StaffListPage"))
const NotifyPage = React.lazy(() => import("./pages/App/NotifyPage"))
const MatchListPage = React.lazy(() => import("./pages/App/Match/MatchListPage"))
const MatchPage = React.lazy(() => import("./pages/App/Match/MatchPage"))

export const routes = [
    {
        path: "/",
        element: <Navigate to='/app/profile'/>
    },
    {
        path: "/app",
        element: <Suspense fallback={<Loader/>}>
            <AppPage/>
        </Suspense>,
        children: [
            {
                path: "",
                element: <Navigate to='profile'/>
            },
            {
                path: "profile",
                element: <Suspense fallback={<Loader/>}>
                    <ProfilePage/>
                </Suspense>,
            },
            {
                path: "staff",
                element: <Suspense fallback={<Loader/>}>
                    <StaffListPage/>
                </Suspense>,
            },
            {
                path: "profile/staff-card",
                element: <Suspense fallback={<Loader/>}>
                    <ProtectedRoute check={baseCheck(permissions.STAFF_CARD)}>
                        <StaffCardPage/>
                    </ProtectedRoute>
                </Suspense>,
            },
            {
                path: "profile/notify",
                element: <Suspense fallback={<Loader/>}>
                    <NotifyPage/>
                </Suspense>,
            },
            {
                path: "profile/match",
                element: <Suspense fallback={<Loader/>}>
                    <ProtectedRoute check={baseCheck(permissions.MATCH_RULES)}>
                        <MatchListPage/>
                    </ProtectedRoute>
                </Suspense>,
            },
            {
                path: "profile/match/:matchId",
                element: <Suspense fallback={<Loader/>}>
                    <ProtectedRoute check={baseCheck(permissions.MATCH_RULES)}>
                        <MatchPage/>
                    </ProtectedRoute>
                </Suspense>,
            },
            {
                path: "profile/groups",
                element: <Suspense fallback={<Loader/>}>
                    <ProtectedRoute check={baseCheck(permissions.SELECT_GROUPS)}>
                        <GroupsPage/>
                    </ProtectedRoute>
                </Suspense>,
            },
            {
                path: "staff/:userId",
                element: <Suspense fallback={<Loader/>}>
                    <ContactsPage/>
                </Suspense>,
            },
            {
                path: "profile/mailing",
                element: <Suspense fallback={<Loader/>}>
                    <ProtectedRoute check={baseCheck(permissions.MAILING)}>
                        <MailingPage/>
                    </ProtectedRoute>
                </Suspense>,
            },
            {
                path: "profile/users",
                element: <Suspense fallback={<Loader/>}>
                    <ProtectedRoute check={baseCheck(permissions.USERS_MANAGEMENT)}>
                        <UsersListPage/>
                    </ProtectedRoute>
                </Suspense>,
            },
            {
                path: "profile/users/:userId",
                element: <Suspense fallback={<Loader/>}>
                    <ProtectedRoute check={baseCheck(permissions.USERS_MANAGEMENT)}>
                        <UserPage/>
                    </ProtectedRoute>
                </Suspense>,
            },
        ]
    },
    {
        path: "/register",
        element: <Suspense fallback={<Loader/>}>
            <RegisterPage/>
        </Suspense>,
        children: [
            {
                path: "",
                element: <Suspense fallback={<Loader/>}>
                    <RegisterSelectPage/>
                </Suspense>,
            },
            {
                path: "/register/staff",
                element: <Suspense fallback={<Loader/>}>
                    <RegisterTeacherPage/>
                </Suspense>,
            },
            {
                path: "/register/student",
                element: <Suspense fallback={<Loader/>}>
                    <RegisterStudentPage/>
                </Suspense>,
            },
        ]
    },
]