import {createSlice} from '@reduxjs/toolkit'
import {fetchSearchContacts} from "./apiSlice";

const initialState = {
    list: [],
    skip: 0,
    total: -1,
    search: '',
    oldSearch: '',
    isLoading: false
}

export const staffSlice = createSlice({
    name: 'staff',
    initialState: initialState,
    reducers: {
        clearStaffList: (state) => {
            state.list = []
        },
        setStaffSearch: (state, action) => {
            state.search = action.payload;
        },
        setStaffOldSearch: (state, action) => {
            state.oldSearch = action.payload;
        },
        setStaffSkip: (state, action) => {
            state.skip = action.payload;
        }
    },
    extraReducers: (builder) => builder
        .addCase(fetchSearchContacts.pending, (state) => {
            state.isLoading = true
        })
        .addCase(fetchSearchContacts.rejected, (state) => {
            state.isLoading = false
        })
        .addCase(fetchSearchContacts.fulfilled, (state, action) => {
            const {total, items} = action.payload
            state.total = total
            state.oldSearch = state.search
            if(state.skip === 0) state.list = items;
            else state.list.push(...items)
            state.isLoading = false
        })
})

export const { clearStaffList, setStaffSearch, setStaffSkip, setStaffOldSearch } = staffSlice.actions

export const selectStaffData = state => state.staff;

export default staffSlice.reducer