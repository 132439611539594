import {createSlice} from '@reduxjs/toolkit'
import {fetchChatGroupsQuery} from "./apiSlice";

const initialState = {
    selectedGroups: {}
}

export const selectedGroupSlice = createSlice({
    name: 'selectedGroup',
    initialState: initialState,
    reducers: {
        setChatSelectedGroups: (state, action) => {
            const {chatId, groups} = action.payload
            state.selectedGroups[chatId] = groups
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchChatGroupsQuery.fulfilled, (state, action) => {
            console.log(action)
            const chatId = action.meta.arg.chatId
            state.selectedGroups[chatId] = action.payload
        })
    },
})

export const { setChatSelectedGroups } = selectedGroupSlice.actions

export const selectSelectedGroups = state => state.selectedGroup.selectedGroups;

export default selectedGroupSlice.reducer